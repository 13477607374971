<template>
  <v-card class="card-custom bg-gray-100 card-stretch gutter-b mt-2">
    <v-dialog persistent v-model="reportDialog" max-width="700">
      <v-card>
        <v-toolbar
            dark
            color="#00004d"
        >
          <v-btn
              icon
              dark
              @click="reportDialog = false; reportLink = '';"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span>Share Report Link</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click.stop.prevent="copyLink"
            >
              <v-icon>fas fa-copy</v-icon>
            </v-btn>
            <v-btn
                dark
                text
                @click="emailLink"
            >
              <v-icon>fas fa-envelope</v-icon>
            </v-btn>
            <v-btn
                dark
                text
                @click="sendToWa"
            >
              <v-icon>fab fa-whatsapp</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <h6>Show Unit History</h6>
                <v-switch
                    v-model="suh"
                    v-bind:color="suh === 1 ? 'success': ''"
                    item-value="value"
                    hide-details
                    @change="setUH($event)"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <h1><a :href="reportLink" target="_blank">{{ reportLink }}</a></h1>
                <input type="hidden" id="report-link" :value="reportLink">
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="uhDialog" max-width="700">
      <v-card>
        <v-toolbar
            dark
            color="#00004d"
        >
          <v-btn
              icon
              dark
              @click="uhDialog = false; uhR = []; uhS = []; activeTabInner = 0; uhHer = ''"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="pl-0" style="text-overflow: unset;white-space: unset">
            <span v-html="'Unit History - ' + uhHer"></span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card flat>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12" sm="12">
                <v-tabs v-model="activeTabInner" background-color="#002366" centered dark>
                  <v-tab>Sale</v-tab>
                  <v-tab>Rent</v-tab>
                </v-tabs>
                <v-tabs-items touchless v-model="activeTabInner">
                  <v-tab-item>
                    <div class="bg-white">
                      <v-simple-table class="c-simple-table">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-left">
                              Date
                            </th>
                            <th class="text-left">
                              Price
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                              v-for="(item, i) in uhS"
                              :key="i"
                          >
                            <td>{{ formatMomentDate(item.date) }}</td>
                            <td>{{ item.price }}</td>
                          </tr>
                          </tbody>
                          <tfoot>
                          <tr>
                            <th>Total</th>
                            <th>{{ uhTS }}</th>
                          </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="bg-white">
                      <v-simple-table class="c-simple-table">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th class="text-left">Date</th>
                            <th class="text-left">Rent</th>
                            <th class="text-left">Start</th>
                            <th class="text-left">End</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                              v-for="(item, i) in uhR"
                              :key="i"
                          >
                            <td>{{ formatMomentDate(item.date) }}</td>
                            <td>{{ item.price }}</td>
                            <td>{{ formatMomentDate(item.rent_start_date) }}</td>
                            <td>{{ formatMomentDate(item.rent_end_date) }}</td>
                          </tr>
                          </tbody>
                          <tfoot>
                          <tr>
                            <th>Total</th>
                            <th>{{ uhTR }}</th>
                            <th>Duration</th>
                            <th>{{ uhRD }}</th>
                          </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
          <div class="text-center font-weight-bold">
            Search/Filters
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.stop.prevent="hitAdvanceSearch">
            <v-row>
              <v-col cols="12" sm="3">
                <v-autocomplete
                    v-model="searchFormData.location"
                    :items="locationsFilter"
                    item-text="text"
                    item-value="title"
                    no-filter
                    clearable
                    outlined
                    dense
                    return-object
                    deletable-chips
                    chips
                    :loading="isLocationsLoaded"
                    small-chips
                    hide-details
                    label="Location (min. 3 characters required)"
                    @keyup="locationsFilterKeyUp"
                    @change="locationChange($event)"
                >
                </v-autocomplete>
                <!--                <v-autocomplete-->
                <!--                    v-model="searchFormData.allSearch"-->
                <!--                    :items="titlesFilter"-->
                <!--                    item-text="full_name"-->
                <!--                    item-value="full_name"-->
                <!--                    no-filter-->
                <!--                    dense-->
                <!--                    hide-details-->
                <!--                    clearable-->
                <!--                    outlined-->
                <!--                    chips-->
                <!--                    return-object-->
                <!--                    @change="allSearchChange"-->
                <!--                    :loading="isTitlesLoaded"-->
                <!--                    small-chips-->
                <!--                    label="Search (Minimum 3 Characters Required)"-->
                <!--                    @keyup="titlesFilterKeyUp"-->
                <!--                >-->
                <!--                </v-autocomplete>-->
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                    dense outlined
                    hide-details
                    clearable
                    @change="drChange"
                    :items="dateRangeFilter"
                    v-model="searchFormData.drSearch"
                    item-text="title"
                    item-value="value"
                    label="Date Range"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field type="date" dense outlined hide-details
                              v-model="searchFormData.fromDateSearch"
                              label="From Date"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field type="date" dense outlined hide-details
                              v-model="searchFormData.toDateSearch"
                              label="To Date"></v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <v-text-field :counter="20"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.minPriceSearch = addTS($event)"
                              maxlength="20" v-model="searchFormData.minPriceSearch"
                              label="Min Price"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field :counter="20"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.maxPriceSearch = addTS($event)"
                              maxlength="20" v-model="searchFormData.maxPriceSearch"
                              label="Max Price"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.minPricePsfSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.minPricePsfSearch"
                              label="Min Price Per SqFt"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              maxLength="10"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.maxPricePsfSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.maxPricePsfSearch"
                              label="Max Price Per SqFt"></v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              maxLength="10"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.minBuaSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.minBuaSearch"
                              label="Min Built Up Area"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              maxLength="10"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.maxBuaSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.maxBuaSearch"
                              label="Max Built Up Area"></v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              maxLength="10"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.minPaSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.minPaSearch"
                              label="Min Plot Area"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              maxLength="10"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.maxPaSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.maxPaSearch"
                              label="Max Plot Area"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              maxLength="10"
                              dense outlined
                              hide-details
                              maxlength="10" v-model="searchFormData.unitNoSearch"
                              label="Unit No."></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                    dense outlined
                    clearable
                    hide-details
                    :items="bedsFilter"
                    v-model="searchFormData.bedSearch"
                    item-text="title"
                    item-value="value"
                    label="Beds"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                    dense outlined
                    clearable
                    hide-details
                    :items="unitTypesFilter"
                    v-model="searchFormData.unitTypeSearch"
                    item-text="title"
                    item-value="value"
                    label="Property Type"
                ></v-autocomplete>
              </v-col>
<!--              <v-col cols="12" sm="2">-->
<!--                <v-select-->
<!--                    dense outlined-->
<!--                    clearable-->
<!--                    hide-details-->
<!--                    :items="primaryResaleFilter"-->
<!--                    v-model="searchFormData.prSearch"-->
<!--                    item-text="title"-->
<!--                    item-value="value"-->
<!--                    label="Primary/Resale"-->
<!--                ></v-select>-->
<!--              </v-col>-->
              <v-col cols="12" sm="2">
                <v-select
                    dense outlined
                    clearable
                    hide-details
                    :items="priceSortFilter"
                    v-model="searchFormData.priceSortSearch"
                    item-text="title"
                    item-value="value"
                    label="Price Sort"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    dense
                    outlined
                    clearable
                    hide-details
                    :items="typeSortFilter"
                    v-model="searchFormData.typeSearch"
                    item-text="title"
                    item-value="value"
                    label="Type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    dense outlined
                    clearable
                    hide-details
                    :items="matchedFilter"
                    v-model="searchFormData.matchSearch"
                    item-text="title"
                    item-value="value"
                    label="Matched With PM"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <button :disabled="loading" type="submit" style="width: 100%"
                        class="btn btn-success theme-btn ml-auto mr-2">
                  Search
                  <v-icon class="ml-2" small>fas fa-search</v-icon>
                </button>
              </v-col>
              <v-col cols="12" sm="4">
                <button type="button" :disabled="loading" style="width: 100%" @click="resetSearchForm"
                        class="btn btn-primary ml-auto">
                  Reset
                  <v-icon class="ml-2" small>fas fa-times</v-icon>
                </button>
              </v-col>
              <v-col cols="12" sm="4">
                <button type="button" v-if="searchFormData.location"
                        @click="generateReport('9qs4d2x51fs48l6o4g1k7gh3v')" style="width: 100%"
                        class="btn btn-success theme-btn ml-auto mr-2">
                  Generate Report
                  <v-icon class="ml-2" small>fas fa-tasks</v-icon>
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-title class="pb-0">
      {{ purpose == 1 ? 'Sales' : 'Rent' }} Transactions ({{ addTSBVZ(totalRecords) }})
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            fixed-header
            height="500"
            :options.sync="pagination"
            :server-items-length="totalRecords"
            :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
            :loading="loading"
            mobile-breakpoint="100"
            ref="mrc-trans-datatable"
            class="elevation-0 custom-td-table">
          <template v-slot:item.type="{ item }">
            <div class="text-truncate">
              <p v-if="item.type === 0">Residential</p>
              <p v-else>Commercial</p>
            </div>
          </template>
          <template v-slot:item.location="{ item }">
            <div class="text-truncate" v-if="item.project_id">
              <a class="hidden-md-and-up"
                 :href="'/developments-management/projects?project='+item.project_id">
                <span v-html="item.location"></span>
              </a>
              <a class="hidden-sm-and-down"
                 :href="'/developments-management/projects?project='+item.project_id" target="_blank">
                <span v-html="item.location"></span>
              </a>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-4" color="primary" dark v-on="on" small>fas fa-question-circle
                  </v-icon>
                </template>
                <span v-html="'Master Development: ' + item.development"></span><br>
                <span v-html="'Sub Development 1: ' + item.community"></span><br>
                <span v-html="'Sub Development 2/Project: ' + item.sub_community"></span><br>
                <span v-html="'Project: ' + item.project"></span>
              </v-tooltip>
            </div>
            <div class="text-truncate"
                 v-if="item.p_development && !item.project_id && item.p_development.type === '0'">
              <a class="hidden-md-and-up"
                 :href="'/developments-management/master-developments?development='+item.development_id">
                <span v-html="item.location"></span>
              </a>
              <a class="hidden-sm-and-down"
                 :href="'/developments-management/master-developments?development='+item.development_id"
                 target="_blank">
                <span v-html="item.location"></span>
              </a>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-4" color="primary" dark v-on="on" small>fas fa-question-circle
                  </v-icon>
                </template>
                <span v-html="'Master Development: ' + item.development"></span><br>
                <span v-html="'Sub Development 1: ' + item.community"></span><br>
                <span v-html="'Sub Development 2/Project: ' + item.sub_community"></span><br>
                <span v-html="'Project: ' + item.project"></span>
              </v-tooltip>
            </div>
            <div class="text-truncate"
                 v-if="item.p_development && !item.project_id && item.p_development.type === '1'">
              <a class="hidden-md-and-up"
                 :href="'/developments-management/sub-developments?development='+item.development_id">
                <span v-html="item.location"></span>
              </a>
              <a class="hidden-sm-and-down"
                 :href="'/developments-management/sub-developments?development='+item.development_id"
                 target="_blank">
                <span v-html="item.location"></span>
              </a>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-4" color="primary" dark v-on="on" small>fas fa-question-circle
                  </v-icon>
                </template>
                <span v-html="'Master Development: ' + item.development"></span><br>
                <span v-html="'Sub Development 1: ' + item.community"></span><br>
                <span v-html="'Sub Development 2/Project: ' + item.sub_community"></span><br>
                <span v-html="'Project: ' + item.project"></span>
              </v-tooltip>
            </div>
            <div class="text-truncate" v-if="!item.development_id && !item.project_id">
              <p>
                <span v-html="item.location"></span>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon class="ml-4" color="primary" dark v-on="on" small>fas
                      fa-question-circle
                    </v-icon>
                  </template>
                  <span v-html="'Master Development: ' + item.development"></span><br>
                  <span v-html="'Sub Development 1: ' + item.community"></span><br>
                  <span v-html="'Sub Development 2/Project: ' + item.sub_community"></span><br>
                  <span v-html="'Project: ' + item.project"></span>
                </v-tooltip>
              </p>
            </div>
          </template>
          <template v-slot:item.date="{ item }">
            <div class="text-truncate">
              <p v-html="formatMomentDate(item.date)"></p>
            </div>
          </template>
          <template v-slot:item.unit_no="{ item }">
            <div class="text-truncate">
              <p v-if="item.p_number">
                <a @click="showUH(item)" v-html="item.unit_no" style="color: blue"></a>
              </p>
              <p v-else v-html="item.unit_no"></p>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-divider class="mb-0"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-card-title class="pt-0">
          Yearly Transactions and {{ purpose == 1 ? 'Sales' : 'Rent' }}
        </v-card-title>
        <div class="bg-white">
          <apexchart ref="yearlyGraph" type="line" height="350" :options="chartOptions"
                     :series="series"></apexchart>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mb-0"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-card-title class="pt-0">
          Transactions and {{ purpose == 1 ? 'Sales' : 'Rent' }} by Property Types
        </v-card-title>
        <div class="bg-white">
          <apexchart ref="ptGraph" type="line" height="350" :options="chartOptions1"
                     :series="series1"></apexchart>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mb-0"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-card-title class="pt-0">
          Average Price Per Square Feet as per {{ purpose == 1 ? 'Sales' : 'Rent' }}
        </v-card-title>
        <div class="bg-white">
          <apexchart ref="avgPpsfGraph" type="line" height="350" :options="chartOptions2"
                     :series="series2"></apexchart>
        </div>
      </v-col>
    </v-row>
    <!--    <v-divider class="mb-0"></v-divider>-->
    <!--    <v-row>-->
    <!--      <v-col cols="12">-->
    <!--        <v-card-title class="pt-0">-->
    <!--          Pricing Summary-->
    <!--        </v-card-title>-->
    <!--        <div class="bg-white">-->
    <!--          <v-simple-table>-->
    <!--            <template v-slot:default>-->
    <!--              <thead>-->
    <!--              <tr>-->
    <!--                <th class="text-left">-->
    <!--                  No. Of Beds-->
    <!--                </th>-->
    <!--                <th class="text-left">-->
    <!--                  No. Transactions-->
    <!--                </th>-->
    <!--                <th class="text-left">-->
    <!--                  Avg. Price Transacted-->
    <!--                </th>-->
    <!--                <th class="text-left">-->
    <!--                  Avg. Price Advertised-->
    <!--                </th>-->
    <!--                <th class="text-left">-->
    <!--                  Min Price Transacted-->
    <!--                </th>-->
    <!--                <th class="text-left">-->
    <!--                  Min Price Advertised-->
    <!--                </th>-->
    <!--                <th class="text-left">-->
    <!--                  Max Price Transacted-->
    <!--                </th>-->
    <!--                <th class="text-left">-->
    <!--                  Max Price Advertised-->
    <!--                </th>-->
    <!--              </tr>-->
    <!--              </thead>-->
    <!--              <tbody>-->
    <!--              <tr-->
    <!--                  v-for="(item, i) in ppsfTableItems"-->
    <!--                  :key="i"-->
    <!--              >-->
    <!--                <td>{{ item.beds }}</td>-->
    <!--                <td>{{ item.count }}</td>-->
    <!--                <td>{{ item.avg_price }}</td>-->
    <!--                <td>{{ item.hasOwnProperty('avg_price_advertised') ? item.avg_price_advertised : '' }}</td>-->
    <!--                <td>{{ item.min_price }}</td>-->
    <!--                <td>{{ item.hasOwnProperty('min_price_advertised') ? item.min_price_advertised : '' }}</td>-->
    <!--                <td>{{ item.max_price }}</td>-->
    <!--                <td>{{ item.hasOwnProperty('max_price_advertised') ? item.max_price_advertised : '' }}</td>-->
    <!--              </tr>-->
    <!--              </tbody>-->
    <!--            </template>-->
    <!--          </v-simple-table>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--    <v-row>-->
    <!--      <v-col cols="12">-->
    <!--        <v-card-title class="pt-0">-->
    <!--          Avg/Min/Max Prices by Bedrooms-->
    <!--        </v-card-title>-->
    <!--        <div class="bg-white">-->
    <!--          <apexchart ref="avgPbbGraph" type="bar" height="1000" :options="chartOptions3" :series="series3"></apexchart>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import apexchart from "vue-apexcharts";
import {
  DASHBOARD_PT_GRAPH,
  DASHBOARD_PPSF_GRAPH,
  DASHBOARD_PBB_GRAPH,
  DASHBOARD_TRANSACTIONS_LIST,
  DASHBOARD_YEARLY_GRAPH,
  TRANSACTIONS_TITLES, DASHBOARD_PBB_TABLE, GET_UNIT_HISTORY, SAVE_REPORT
} from "@/core/services/store/dashboard.module";
// import Swal from "sweetalert2";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
import {DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST} from "@/core/services/store/developments.module";

export default {
  name: "transactions-table",
  components: {
    apexchart,
  },
  props: ['purpose'],
  data() {
    return {
      panel: 0,
      headers: [
        {text: 'Date', value: 'date', name: 'date', filterable: false, sortable: false},
        {text: 'Type', value: 'type', name: 'type', filterable: false, sortable: false},
        // {text: 'Primary/Resale', value: 'data_point', name: 'data_point', filterable: false, sortable: false},
        {text: 'Location', value: 'location', name: 'location', filterable: false, sortable: false},
        // {text: 'Master Development', value: 'development', name: 'development', filterable: false, sortable: false},
        // {text: 'Sub Development 1', value: 'community', name: 'community', filterable: false, sortable: false},
        // {text: 'Sub Development 2/Project', value: 'sub_community', name: 'sub_community', filterable: false, sortable: false},
        // {text: 'Project', value: 'project', name: 'project', filterable: false, sortable: false},
        {text: 'Unit No.', value: 'unit_no', name: 'unit_no', filterable: false, sortable: false},
        {text: 'Beds', value: 'beds', name: 'beds', filterable: false, sortable: false},
        {text: "Price", value: "price", name: "price", filterable: false, sortable: false},
        {text: "Built Up Area", value: "unit_size", name: "unit_size", filterable: false, sortable: false},
        {text: "Plot Area", value: "plot_size", name: "plot_size", filterable: false, sortable: false},
        {
          text: "Price Per SqFt",
          value: "price_per_sqft",
          name: "price_per_sqft",
          filterable: false,
          sortable: false
        },
        // {text: "Villa Type", value: "villa_type", name: "villa_type", filterable: false, sortable: false},
        {text: "Property Type", value: "unit_type", name: "unit_type", filterable: false, sortable: false},
        {text: "Floor Level", value: "floor_level", name: "floor_level", filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      uhDialog: false,
      uhHer: '',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      items: [],
      locationsFilter: [],
      titlesFilter: [],
      isLocationsLoaded: false,
      isTitlesLoaded: false,
      searchFormData: {},
      transactionCount: [],
      series: [
        {
          name: 'Transactions',
          type: 'column',
          data: []
        },
        {
          name: 'Sales',
          type: 'line',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1]
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: 'Transactions',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          },
          {
            opposite: true,
            title: {
              text: 'Sales'
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      series1: [
        {
          name: 'Transactions',
          type: 'column',
          data: []
        },
        {
          name: 'Sales',
          type: 'line',
          data: []
        }
      ],
      chartOptions1: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1]
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: 'Transactions',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          },
          {
            opposite: true,
            title: {
              text: 'Sales'
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      series2: [
        {
          name: 'Price',
          type: 'line',
          data: []
        }
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [4]
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0]
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: 'Price',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      series3: [
        {
          name: 'Avg. Price',
          data: []
        },
        {
          name: 'Min. Price',
          data: []
        },
        {
          name: 'Max. Price',
          data: []
        }
      ],
      chartOptions3: {
        chart: {
          height: 1000,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
            endingShape: 'rounded'
          }
        },
        colors: ['#44b973', '#0c69b6', '#081746'],
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toLocaleString('en-US');
          }
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: 'Bedrooms',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      reportDialog: false,
      reportLink: '',
      ppsfTableItems: [],
      suh: 0,
      uhS: [],
      uhR: [],
      uhTS: "",
      uhTR: "",
      uhRD: ""
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
          this.datatableScrollToTop('mrc-trans-datatable')
          this.getYearlyGraph();
          // this.getPbbTable();
        });
      },
      deep: true
    },
    reportDialog(val) {
      if (!val) this.suh = 0;
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery ? this.searchQuery : ''
      };
    },
    ...mapState({
      errors: state => state.dashboard.errors
    })
  },
  mounted() {
    for (let i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.searchFormData = {purposeSearch: this.purpose}
  },
  methods: {
    // searchQueryData: function (e) {
    //   if (e.keyCode === 13) {
    //     this.searchQuery = e.target.value;
    //   }
    // },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        if (searchFormDataQuery) query += '&' + searchFormDataQuery;
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 0 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(DASHBOARD_TRANSACTIONS_LIST, {q: query, columns: this.columns}).then((res) => {
          // this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    getYearlyGraph() {
      let query = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      this.$store.dispatch(DASHBOARD_YEARLY_GRAPH, {q: query}).then((res) => {
        this.$refs.yearlyGraph.updateOptions(
            {xaxis: {categories: res.records.years}}
        )
        this.$refs.yearlyGraph.updateSeries([
          {data: res.records.transactions},
          {data: res.records.sales},
        ])
        this.getPtGraph();
      });
    },
    getPtGraph() {
      let query = '';
      let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      if (searchFormDataQuery) query = searchFormDataQuery;
      this.$store.dispatch(DASHBOARD_PT_GRAPH, {q: query}).then((res) => {
        this.$refs.ptGraph.updateOptions(
            {xaxis: {categories: res.records.unit_types}}
        )
        this.$refs.ptGraph.updateSeries([
          {data: res.records.transactions},
          {data: res.records.sales},
        ])
        this.getPpsfGraph();
      });
    },
    getPpsfGraph() {
      let query = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      this.$store.dispatch(DASHBOARD_PPSF_GRAPH, {q: query}).then((res) => {
        this.$refs.avgPpsfGraph.updateOptions(
            {xaxis: {categories: res.records.years}}
        )
        this.$refs.avgPpsfGraph.updateSeries([
          {data: res.records.sales},
        ])
        // this.getPbbTable();
      });
    },
    getPbbfGraph() {
      let query = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      this.$store.dispatch(DASHBOARD_PBB_GRAPH, {q: query}).then((res) => {
        this.$refs.avgPbbGraph.updateOptions(
            {xaxis: {categories: res.records.beds}}
        )
        this.$refs.avgPbbGraph.updateSeries([
          {data: res.records.avg_prices},
          {data: res.records.min_prices},
          {data: res.records.max_prices},
        ])
      });
    },
    getPbbTable() {
      this.ppsfTableItems = [];
      let query = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      this.$store.dispatch(DASHBOARD_PBB_TABLE, {q: query}).then((res) => {
        this.ppsfTableItems = res.records
      });
    },
    hitAdvanceSearch() {
      this.pagination.page = 1
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getYearlyGraph();
      });
    },
    resetSearchForm() {
      this.pagination.page = 1
      this.searchFormData = {purposeSearch: this.purpose}
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getYearlyGraph();
      });
    },
    titlesFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isTitlesLoaded = true;
          that.$store
              .dispatch(TRANSACTIONS_TITLES, {
                q: e.target.value,
              })
              .then(data => {
                that.titlesFilter = data.records.transactionsTitles;
                that.isTitlesLoaded = false;
              });
        }
      }, 900);
    },
    locationsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isLocationssLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST, {
                type: 'all',
                q: e.target.value,
                countryId: 188,
                cityId: 1
              })
              .then(data => {
                that.locationsFilter = data.records.results;
                that.isLocationssLoaded = false;
              });
        }
      }, 900);
    },
    locationChange(e) {
      if (e) {
        this.searchFormData.location = e.title
        // this.searchFormData.location_id = e.id
        this.searchFormData.location_ref = e.location_ref
      } else {
        this.searchFormData.location = ''
        // this.searchFormData.location_id = ''
        this.searchFormData.location_ref = ''
      }
    },
    // allSearchChange(obj) {
    //     if (obj) {
    //         this.searchFormData.location_id = obj.id
    //         this.searchFormData.location_ref = obj.location_ref
    //     }
    // },
    drChange(v) {
      if (v) {
        if (v === "1") {
          let date = new Date();
          let cdate = new Date();
          let lastmonths = new Date(cdate.setMonth(cdate.getMonth() - 1));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = lastmonths.getFullYear() + '-' + ('0' + (lastmonths.getMonth() + 1)).slice(-2) + '-' + ('0' + lastmonths.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "2") {
          let date = new Date();
          let cdate = new Date();
          let last3months = new Date(cdate.setMonth(cdate.getMonth() - 2));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last3months.getFullYear() + '-' + ('0' + (last3months.getMonth())).slice(-2) + '-' + ('0' + last3months.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "3") {
          let date = new Date();
          let cdate = new Date();
          let last6months = new Date(cdate.setMonth(cdate.getMonth() - 5));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last6months.getFullYear() + '-' + ('0' + (last6months.getMonth())).slice(-2) + '-' + ('0' + last6months.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "4") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = (date.getFullYear() - 1) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "5") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = date.getFullYear() + '-01-01';
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        }
      } else {
        this.searchFormData.toDateSearch = ''
        this.searchFormData.fromDateSearch = ''
      }
    },
    setUH(e) {
      if (e) {
        this.suh = 1
        this.generateReport('0p94f5o5ge4b1g5h4r5i2d6c7x')
      } else {
        this.suh = 0
        this.generateReport('')
      }
    },
    generateReport(tkn) {
      let domain = '';
      if (this.$store.state.auth.user.team_id === 1) {
        domain = 'dubricks.com'
      }
      if (this.$store.state.auth.user.team_id === 2) {
        domain = 'afsrealestate.com'
      }
      let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      if (searchFormDataQuery) {
        let url = 'https://' + domain + '/area-report?' + searchFormDataQuery + '&tkn=' + tkn
        this.$store.dispatch(SAVE_REPORT, {
          url: url,
          title: 'Market Insights',
          description: 'Transacted',
        }).then(data => {
          this.reportLink = 'https://reports.' + domain + '/' + data.records.u_id
          // this.reportLink = 'http://urls.de/' + data.records.u_id
          this.reportDialog = true
        });
      }
    },
    sendToWa() {
      let text = '';
      if (this.searchFormData.location) text += 'Location: ' + this.searchFormData.location + '\n';
      if (this.searchFormData.toDateSearch) text += 'To Date: ' + this.formatMomentDate(this.searchFormData.toDateSearch) + '\n';
      if (this.searchFormData.fromDateSearch) text += 'From Date: ' + this.formatMomentDate(this.searchFormData.fromDateSearch) + '\n';
      if (this.searchFormData.minPriceSearch) text += 'Minimum Price: ' + this.searchFormData.minPriceSearch + '\n';
      if (this.searchFormData.maxPriceSearch) text += 'Maximum Price: ' + this.searchFormData.maxPriceSearch + '\n';
      if (this.searchFormData.minPricePsfSearch) text += 'Minimum Price Per Sqft: ' + this.searchFormData.minPricePsfSearch + '\n';
      if (this.searchFormData.maxPricePsfSearch) text += 'Maximum Price Per Sqft: ' + this.searchFormData.maxPricePsfSearch + '\n';
      if (this.searchFormData.minBuaSearch) text += 'Minimum Built Up Area: ' + this.searchFormData.minBuaSearch + '\n';
      if (this.searchFormData.maxBuaSearch) text += 'Maximum Built Up Area: ' + this.searchFormData.maxBuaSearch + '\n';
      if (this.searchFormData.minPaSearch) text += 'Minimum Plot Area: ' + this.searchFormData.minPaSearch + '\n';
      if (this.searchFormData.maxPaSearch) text += 'Maximum Plot Area: ' + this.searchFormData.maxPaSearch + '\n';
      if (this.searchFormData.unitNoSearch) text += 'Unit Number: ' + this.searchFormData.unitNoSearch + '\n';
      if (this.searchFormData.bedSearch) text += 'Number Of Bedrooms: ' + this.searchFormData.bedSearch + '\n';
      if (this.searchFormData.unitTypeSearch) text += 'Property Type: ' + this.searchFormData.unitTypeSearch + '\n';
      text += '\nReport Link:' + '\n' + this.reportLink
      window.open('https://wa.me?text=' + encodeURIComponent(text), '_blank');
    },
    copyLink() {
      let linkToCopy = document.querySelector('#report-link')
      linkToCopy.setAttribute('type', 'text')
      linkToCopy.select()
      try {
        let successful = document.execCommand('copy');
        if (successful) {
          this.$store.dispatch(SET_SNACK, {clr: 'success', msg: 'Report Link Copied Successfully'});
        } else {
          this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Report Link Not Copied'});
        }
      } catch (err) {
        this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Oops, Unable To Copy'});
      }
      linkToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    emailLink() {
      this.$store.state.reports.sharedReportLink = this.reportLink;
      this.$router.push({
        name: 'contacts',
        query: {share: 'report'}
      })
    },
    showUH(item) {
      this.$store.dispatch(GET_UNIT_HISTORY, {id: item.p_number, un:item.unit_no}).then((res) => {
        this.uhS = res.records.sales
        this.uhR = res.records.rent
        this.uhTS = res.records.totalSales
        this.uhTR = res.records.totalRent
        if (res.records.hasOwnProperty('rentDuration')) {
          this.uhRD = res.records.rentDuration
        }
        this.uhHer = item.location + ' > ' + item.unit_no;
        this.uhDialog = true
      })
    }
  }
};
</script>
