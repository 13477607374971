<template>
  <v-card class="card-custom bg-gray-100 card-stretch gutter-b mt-2">
    <v-dialog persistent v-model="reportDialog" max-width="700">
      <v-card>
        <v-toolbar
            dark
            color="#00004d"
        >
          <v-btn
              icon
              dark
              @click="reportDialog = false; reportLink = '';"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span>Share Report Link</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click.stop.prevent="copyLink"
            >
              <v-icon>fas fa-copy</v-icon>
            </v-btn>
            <v-btn
                dark
                text
                @click="emailLink"
            >
              <v-icon>fas fa-envelope</v-icon>
            </v-btn>
            <v-btn
                dark
                text
                @click="sendToWa"
            >
              <v-icon>fab fa-whatsapp</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <h6>Show Agency Name</h6>
                <v-switch
                    v-model="showAgency"
                    v-bind:color="showAgency === 1 ? 'success': ''"
                    item-value="value"
                    hide-details
                    @change="setAgency($event)"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <h6>Show Ad</h6>
                <v-switch
                    v-model="showAd"
                    v-bind:color="showAd === 1 ? 'success': ''"
                    item-value="value"
                    hide-details
                    @change="setAd($event)"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <h1><a :href="reportLink" target="_blank">{{ reportLink }}</a></h1>
                <input type="hidden" id="ad-report-link" :value="reportLink">
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
          <div class="text-center font-weight-bold">
            Search/Filters
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.stop.prevent="hitAdvanceSearch">
            <v-row>
              <v-col cols="12" sm="4">
                <v-autocomplete
                    v-model="searchFormData.location"
                    :items="locationsFilter"
                    item-text="text"
                    item-value="title"
                    no-filter
                    clearable
                    outlined
                    dense
                    return-object
                    deletable-chips
                    chips
                    :loading="isLocationsLoaded"
                    small-chips
                    hide-details
                    label="Location (min. 3 characters required)"
                    @keyup="locationsFilterKeyUp"
                    @change="locationChange($event)"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="2">
                <v-autocomplete
                    dense outlined
                    clearable
                    hide-details
                    :items="sourcesFilterAdsAds"
                    v-model="searchFormData.source"
                    item-text="title"
                    item-value="value"
                    label="Source"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="2">
                <v-autocomplete
                    dense outlined
                    clearable
                    hide-details
                    :items="agenciesFilterAds"
                    v-model="searchFormData.agency"
                    item-text="title"
                    item-value="value"
                    label="Agency"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="2">
                <v-text-field :counter="20"
                              maxLength="20"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.minPriceSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.minPriceSearch"
                              label="Min Price"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field :counter="20"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.maxPriceSearch = addTS($event)"
                              maxlength="20" v-model="searchFormData.maxPriceSearch"
                              label="Max Price"></v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.minBuaSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.minBuaSearch"
                              label="Min Size"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field :counter="10"
                              dense outlined
                              hide-details
                              @keypress="isNumber($event)"
                              @keyup="searchFormData.maxBuaSearch = addTS($event)"
                              maxlength="10" v-model="searchFormData.maxBuaSearch"
                              label="Max Size"></v-text-field>
              </v-col>

              <!--              <v-col cols="12" sm="2">-->
              <!--                <v-text-field :counter="10"-->
              <!--                              maxLength="10"-->
              <!--                              dense outlined-->
              <!--                              hide-details-->
              <!--                              maxlength="10" v-model="searchFormData.minPaSearch"-->
              <!--                              label="Min Plot Area"></v-text-field>-->
              <!--              </v-col>-->
              <!--              <v-col cols="12" sm="2">-->
              <!--                <v-text-field :counter="10"-->
              <!--                              maxLength="10"-->
              <!--                              dense outlined-->
              <!--                              hide-details-->
              <!--                              maxlength="10" v-model="searchFormData.maxPaSearch"-->
              <!--                              label="Max Plot Area"></v-text-field>-->
              <!--              </v-col>-->
              <v-col cols="12" sm="2">
                <v-autocomplete
                    dense outlined
                    clearable
                    hide-details
                    :items="bedsFilter"
                    v-model="searchFormData.bedSearch"
                    item-text="title"
                    item-value="value"
                    label="Beds"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                    dense outlined
                    clearable
                    hide-details
                    :items="unitTypesFilterAds"
                    v-model="searchFormData.unitTypeSearch"
                    item-text="title"
                    item-value="value"
                    label="Property Type"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    dense outlined
                    clearable
                    hide-details
                    :items="priceSortFilter"
                    v-model="searchFormData.priceSortSearch"
                    item-text="title"
                    item-value="value"
                    label="Price Sort"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    dense
                    outlined
                    clearable
                    hide-details
                    :items="typeSortFilterAds"
                    v-model="searchFormData.typeSearch"
                    item-text="title"
                    item-value="value"
                    label="Type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    dense
                    outlined
                    clearable
                    hide-details
                    :items="purposeFilters"
                    v-model="searchFormData.purposeSearch"
                    item-text="title"
                    item-value="value"
                    label="Purpose"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    dense
                    outlined
                    clearable
                    hide-details
                    :items="fsFilters"
                    v-model="searchFormData.fsSearch"
                    item-text="title"
                    item-value="value"
                    label="Furnishing Status"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                    dense outlined
                    clearable
                    hide-details
                    :items="rfSortFilterAds"
                    v-model="searchFormData.rent_frequency"
                    item-text="title"
                    item-value="value"
                    label="Rent Frequency"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    dense outlined
                    clearable
                    hide-details
                    :items="matchedFilter"
                    v-model="searchFormData.matchSearch"
                    item-text="title"
                    item-value="value"
                    label="Matched"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <button :disabled="loading" type="submit" style="width: 100%"
                        class="btn btn-success theme-btn ml-auto mr-2">
                  Search
                  <v-icon class="ml-2" small>fas fa-search</v-icon>
                </button>
              </v-col>
              <v-col cols="12" sm="4">
                <button :disabled="loading" style="width: 100%" @click="resetSearchForm"
                        class="btn btn-primary ml-auto">
                  Reset
                  <v-icon class="ml-2" small>fas fa-times</v-icon>
                </button>
              </v-col>
              <v-col cols="12" sm="4">
                <button type="button" v-if="searchFormData.location" @click="generateReport"
                        style="width: 100%"
                        class="btn btn-success theme-btn ml-auto mr-2">
                  Generate Report
                  <v-icon class="ml-2" small>fas fa-tasks</v-icon>
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-title class="pb-0">
      Advertisements ({{ addTSBVZ(totalRecords) }}) Updated As Of {{ formatMomentDate($store.state.auth.c_d) }}
      - Bayut ({{ 'S = ' + bayutSCount }}, {{ 'R = ' + bayutRCount }}) - Dubizzle ({{ 'S = ' + dubSCount }},
      {{ 'R = ' + dubRCount }})
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            fixed-header
            height="500"
            :options.sync="pagination"
            :server-items-length="totalRecords"
            :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
            :loading="loading"
            mobile-breakpoint="100"
            ref="mrc-ads-datatable"
            class="elevation-0 custom-td-table">
          <template v-slot:item.type="{ item }">
            <div class="text-truncate">
              <p v-if="item.type === 'residential'">Residential</p>
              <p v-else>Commercial</p>
            </div>
          </template>
          <template v-slot:item.sub_type="{ item }">
            <div class="text-truncate">
              <p v-if="item.sub_type === 'sale'">Sale</p>
              <p v-else>Rent</p>
            </div>
          </template>
          <template v-slot:item.location="{ item }">
            <div class="text-truncate" v-if="item.project_id">
              <a class="hidden-md-and-up"
                 :href="'/developments-management/projects?project='+item.project_id">
                <span v-html="item.location"></span>
              </a>
              <a class="hidden-sm-and-down"
                 :href="'/developments-management/projects?project='+item.project_id" target="_blank">
                <span v-html="item.location"></span>
              </a>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-4" color="primary" dark v-on="on" small>fas fa-question-circle
                  </v-icon>
                </template>
                <span v-html="'Location: ' + item.location"></span>
              </v-tooltip>
            </div>
            <div class="text-truncate"
                 v-if="item.p_development && !item.project_id && item.p_development.type === '0'">
              <a class="hidden-md-and-up"
                 :href="'/developments-management/master-developments?development='+item.development_id">
                <span v-html="item.location"></span>
              </a>
              <a class="hidden-sm-and-down"
                 :href="'/developments-management/master-developments?development='+item.development_id"
                 target="_blank">
                <span v-html="item.location"></span>
              </a>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-4" color="primary" dark v-on="on" small>fas fa-question-circle
                  </v-icon>
                </template>
                <span v-html="'Location: ' + item.location"></span>
              </v-tooltip>
            </div>
            <div class="text-truncate"
                 v-if="item.p_development && !item.project_id && item.p_development.type === '1'">
              <a class="hidden-md-and-up"
                 :href="'/developments-management/sub-developments?development='+item.development_id">
                <span v-html="item.location"></span>
              </a>
              <a class="hidden-sm-and-down"
                 :href="'/developments-management/sub-developments?development='+item.development_id"
                 target="_blank">
                <span v-html="item.location"></span>
              </a>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-4" color="primary" dark v-on="on" small>fas fa-question-circle
                  </v-icon>
                </template>
                <span v-html="'Location: ' + item.location"></span>
              </v-tooltip>
            </div>
            <div class="text-truncate" v-if="!item.development_id && !item.project_id">
              <p>
                <span v-html="item.location"></span>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon class="ml-4" color="primary" dark v-on="on" small>fas
                      fa-question-circle
                    </v-icon>
                  </template>
                  <span v-html="'Location: ' + item.location"></span>
                </v-tooltip>
              </p>
            </div>
          </template>
          <template v-slot:item.agency="{ item }">
            <div class="text-truncate">
              <p v-html="item.agency + ' (S=' + item.agency_sale_ads + ')' + ' (R=' + item.agency_rent_ads + ')' "></p>
            </div>
          </template>
          <template v-slot:item.external_id="{ item }">
            <div class="text-truncate">
              <p v-if="item.source === 'bayut'">
                <a target="_blank"
                   :href="'https://www.bayut.com/property/details-' + item.external_id + '.html'">View
                  Ad</a>
              </p>
              <p v-else>
                <a target="_blank" :href="item.url">View Ad</a>
              </p>
            </div>
          </template>
          <template v-slot:item.source="{ item }">
            <div class="text-truncate">
              <p v-if="item.source === 'bayut'">Bayut</p>
              <p v-if="item.source === 'dubizzle'">Dubizzle</p>
              <p v-if="item.source === 'PF'">PF</p>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-card-title class="pb-0 pt-0">
      Agencies
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers2"
            :items="items2"
            :search="search"
            height="500px"
            mobile-breakpoint="100"
            class="elevation-0">
          <template v-slot:item.agency="{ item }">
            <div class="text-truncate">
              <a @click="selectAgency(item.agency)" v-html="item.agency" style="color: blue"></a>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import {
  ADS_DEPS,
  ADS_LIST, SAVE_REPORT
} from "@/core/services/store/dashboard.module";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
import {DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST} from "@/core/services/store/developments.module";

export default {
  name: "ads-table",
  components: {},
  data() {
    return {
      panel: 0,
      headers: [
        {
          text: "Created",
          align: ' d-none',
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: true
        },
        {text: 'Source', value: 'source', name: 'source', filterable: false, sortable: false},
        {text: 'Type', value: 'type', name: 'type', filterable: false, sortable: false},
        {text: 'Purpose', value: 'sub_type', name: 'sub_type', filterable: false, sortable: false},
        {text: 'View Ad', value: 'external_id', name: 'external_id', filterable: false, sortable: false},
        {text: 'Location', value: 'location', name: 'location', filterable: false, sortable: false},
        // {text: 'Master Development', value: 'development', name: 'development', filterable: false, sortable: false},
        // {text: 'Sub Development 1', value: 'community', name: 'community', filterable: false, sortable: false},
        // {text: 'Sub Development 2/Project', value: 'sub_community', name: 'sub_community', filterable: false, sortable: false},
        // {text: 'Project', value: 'project', name: 'project', filterable: false, sortable: false},
        {text: 'Agency', value: 'agency', name: 'agency', filterable: false, sortable: false},
        {text: 'Beds', value: 'beds', name: 'beds', filterable: false, sortable: false},
        {text: 'Baths', value: 'baths', name: 'baths', filterable: false, sortable: false},
        {text: "Price", value: "price", name: "price", filterable: false, sortable: false},
        {text: "Size", value: "size", name: "size", filterable: false, sortable: false},
        {
          text: "Furnishing Status",
          value: "furnishing_status",
          name: "furnishing_status",
          filterable: false,
          sortable: false
        },
        {
          text: "Rent Frequency",
          value: "rent_frequency",
          name: "rent_frequency",
          filterable: false,
          sortable: false
        },
      ],
      headers2: [
        {text: 'Sr. #', value: 'srno'},
        {text: 'Agency Name', value: 'agency', name: 'agency'},
        {text: 'Total Ads', value: 'count'},
        {text: 'Sales Ads', value: 'sale_count'},
        {text: 'Rental Ads', value: 'rent_count'},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      items: [],
      items2: [],
      search: '',
      locationsFilter: [],
      isLocationsLoaded: false,
      titlesFilter: [],
      isTitlesLoaded: false,
      searchFormData: {},
      transactionCount: [],
      unitTypesFilterAds: [],
      agenciesFilterAds: [],
      reportDialog: false,
      reportLink: '',
      bayutSCount: 0,
      bayutRCount: 0,
      dubSCount: 0,
      dubRCount: 0,
      pfSCount: 0,
      pfRCount: 0,
      showAgency: 0,
      showAgencyTkn: '',
      showAd: 0,
      showAdTkn: '',
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
          this.datatableScrollToTop('mrc-ads-datatable')
          this.getAdsDeps();
        });
      },
      deep: true
    },
    reportDialog(val) {
      if (!val) this.showAgency = 0;
      if (!val) this.showAgencyTkn = '';
      if (!val) this.showAd = 0;
      if (!val) this.showAdTkn = '';
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery ? this.searchQuery : ''
      };
    },
    ...mapState({
      errors: state => state.dashboard.errors
    })
  },
  mounted() {
    for (let i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
  },
  methods: {
    // searchQueryData: function (e) {
    //   if (e.keyCode === 13) {
    //     this.searchQuery = e.target.value;
    //   }
    // },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        if (searchFormDataQuery) query += '&' + searchFormDataQuery;
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 0 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(ADS_LIST, {q: query, columns: this.columns}).then((res) => {
          // this.draw++;
          let items = res.records.data.data;
          let total = res.records.data.recordsFiltered;
          this.bayutSCount = res.records.bayutSCount;
          this.bayutRCount = res.records.bayutRCount;
          this.dubSCount = res.records.dubSCount;
          this.dubRCount = res.records.dubRCount;
          this.pfSCount = res.records.pfSCount;
          this.pfRCount = res.records.pfRCount;
          this.items2 = res.records.agencyAdsCount;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    getAdsDeps() {
      this.$store.dispatch(ADS_DEPS).then((res) => {
        this.unitTypesFilterAds = res.records.unit_types;
        this.agenciesFilterAds = res.records.agencies;
      });
    },
    hitAdvanceSearch() {
      this.pagination.page = 1
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getAdsDeps();
      });
    },
    resetSearchForm() {
      this.pagination.page = 1
      this.searchFormData = {};
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getAdsDeps();
      });
    },
    locationsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isLocationssLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST, {
                type: 'all',
                q: e.target.value,
                countryId: 188,
                cityId: 1
              })
              .then(data => {
                that.locationsFilter = data.records.results;
                that.isLocationssLoaded = false;
              });
        }
      }, 900);
    },
    locationChange(e) {
      if (e) {
        this.searchFormData.location = e.title
        // this.searchFormData.location_id = e.id
        this.searchFormData.location_ref = e.location_ref
      } else {
        this.searchFormData.location = ''
        // this.searchFormData.location_id = ''
        this.searchFormData.location_ref = ''
      }
    },
    // allSearchChange(obj) {
    //     if (obj) {
    //         this.searchFormData.location_id = obj.id
    //         this.searchFormData.location_ref = obj.location_ref
    //     }
    // },
    generateReport() {
      // this.searchFormData.tkn = this.$store.state.auth.t_id
      let domain = '';
      if (this.$store.state.auth.user.team_id === 1) {
        domain = 'dubricks.com'
      }
      if (this.$store.state.auth.user.team_id === 2) {
        domain = 'afsrealestate.com'
      }
      let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
      }).join('&');
      if (searchFormDataQuery) {
        let url = 'https://' + domain + '/ads-area-report?' + searchFormDataQuery + '&tkn=' + this.showAgencyTkn + '&okn=' + this.showAdTkn
        this.$store.dispatch(SAVE_REPORT, {
          url: url,
          title: 'Market Insights',
          description: 'Advertised',
        }).then(data => {
          this.reportLink = 'https://reports.' + domain + '/' + data.records.u_id
          // this.reportLink = 'http://urls.de/' + data.records.u_id
          this.reportDialog = true
        });
      }
    },
    copyLink() {
      let linkToCopy = document.querySelector('#ad-report-link')
      linkToCopy.setAttribute('type', 'text')
      linkToCopy.select()

      try {
        let successful = document.execCommand('copy');
        if (successful) {
          this.$store.dispatch(SET_SNACK, {clr: 'success', msg: 'Report Link Copied Successfully'});
        } else {
          this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Report Link Not Copied'});
        }
      } catch (err) {
        this.$store.dispatch(SET_SNACK, {clr: 'error', msg: 'Oops, Unable To Copy'});
      }
      linkToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    sendToWa() {
      let text = '';
      if (this.searchFormData.location) text += 'Location: ' + this.searchFormData.location + '\n';
      if (this.searchFormData.minPriceSearch) text += 'Minimum Price: ' + this.searchFormData.minPriceSearch + '\n';
      if (this.searchFormData.maxPriceSearch) text += 'Maximum Price: ' + this.searchFormData.maxPriceSearch + '\n';
      if (this.searchFormData.minBuaSearch) text += 'Minimum Size: ' + this.searchFormData.minBuaSearch + '\n';
      if (this.searchFormData.maxBuaSearch) text += 'Maximum Size: ' + this.searchFormData.maxBuaSearch + '\n';
      if (this.searchFormData.bedSearch) text += 'Number Of Bedrooms: ' + this.searchFormData.bedSearch + '\n';
      if (this.searchFormData.purposeSearch) text += 'Purpose: ' + this.searchFormData.purposeSearch + '\n';
      if (this.searchFormData.fsSearch) text += 'Furnishing Status: ' + this.searchFormData.fsSearch + '\n';
      if (this.searchFormData.rent_frequency) text += 'Rent Frequency: ' + this.searchFormData.rent_frequency + '\n';
      if (this.searchFormData.unitTypeSearch) text += 'Property Type: ' + this.searchFormData.unitTypeSearch + '\n';
      text += '\nReport Link:' + '\n' + this.reportLink
      window.open('https://wa.me?text=' + encodeURIComponent(text), '_blank');
    },
    emailLink() {
      this.$store.state.reports.sharedReportLink = this.reportLink;
      this.$router.push({
        name: 'contacts',
        query: {share: 'report'}
      })
    },
    selectAgency(agency) {
      this.searchFormData.agency = agency;
      this.hitAdvanceSearch()
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    setAgency(e) {
      if (e) {
        this.showAgency = 1
        this.showAgencyTkn = '9w1q0p11x23im8x7m0n89d1l8b2t0j'
        this.generateReport()
      } else {
        this.showAgency = 0
        this.showAgencyTkn = ''
        this.generateReport()
      }
    },
    setAd(e) {
      if (e) {
        this.showAd = 1
        this.showAdTkn = '08ykazh18sf7cyx839h3yuu52s9s5a'
        this.generateReport()
      } else {
        this.showAd = 0
        this.showAdTkn = ''
        this.generateReport()
      }
    },
  }
};
</script>
